<template>
  <div>
    <!-- Form: Invoice Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: reference -->
          <validation-provider
            #default="validationContext"
            vid="reference"
            name="reference"
            rules="required"
          >
            <b-form-group
              label="رقم الفاتورة"
              label-for="reference"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="reference"
                v-model="invoiceData.reference"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: total -->
          <validation-provider
            #default="validationContext"
            vid="total"
            name="total"
            rules="required"
          >
            <b-form-group
              label="المبلغ"
              label-for="total"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="total"
                v-model="invoiceData.total"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: starts_at -->
          <validation-provider
            #default="validationContext"
            vid="starts_at"
            name="starts_at"
            rules="required|validEmail"
          >
            <b-form-group
              label="الفترة من"
              label-for="starts_at"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="starts-at"
                v-model="invoiceData.starts_at"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: ends_at -->
          <validation-provider
            #default="validationContext"
            vid="ends_at"
            name="ends_at"
            rules="required"
          >
            <b-form-group
              label="الفترة إلى"
              label-for="ends_at"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="ends-at"
                v-model="invoiceData.ends_at"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: payment_status -->
          <validation-provider
            #default="validationContext"
            vid="payment_status"
            name="payment_status"
            rules="required"
          >
            <b-form-group
              label="حالة السداد"
              label-for="payment_status"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="payment-status"
                v-model="invoiceData.payment_status"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: payment_method -->
          <validation-provider
            #default="validationContext"
            vid="payment_method"
            name="payment_method"
            rules="required"
          >
            <b-form-group
              label="قناة السداد"
              label-for="payment_method"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="payment-method"
                v-model="invoiceData.payment_method"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: paid_at -->
          <validation-provider
            #default="validationContext"
            vid="paid_at"
            name="paid_at"
            rules="required"
          >
            <b-form-group
              label="تاريخ السداد"
              label-for="paid_at"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="paid-at"
                v-model="invoiceData.paid_at"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: transaction_number -->
          <validation-provider
            #default="validationContext"
            vid="transaction_number"
            name="transaction_number"
            rules="required"
          >
            <b-form-group
              label="رقم معاملة السداد"
              label-for="transaction_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="transaction-number"
                v-model="invoiceData.transaction_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateInvoice"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import invoiceStore from '../invoiceStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    updateInvoice() {
      this.$store
        .dispatch('app-invoice/updateInvoice', { invoiceData: this.invoiceData, id: this.invoiceData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/invoice').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },

    payment_statusChanged(payment_status) {
      this.invoiceData.payment_status_id = payment_status;
    },
    payment_methodChanged(payment_method) {
      this.invoiceData.payment_method_id = payment_method;
    },
  },

  setup(props) {
    const INVOICE_STORE_MODULE_NAME = 'invoice';

    // Register invoice
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    const payment_statuses = [
      {
        label: 'لم يتم السداد',
        value: 'unpaid',
      },
      {
        label: 'تم السداد',
        value: 'paid',
      },
    ];
    const payment_methods = [
      {
        label: 'كاش',
        value: 'cash',
      },
      {
        label: 'تحويل بنكي',
        value: 'transfer',
      },
    ];
    const oldInvoiceData = JSON.parse(JSON.stringify(props.invoiceData));
    const resetInvoiceData = () => {
      invoiceData.value = JSON.parse(JSON.stringify(oldInvoiceData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetInvoiceData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      payment_statuses,
      payment_methods,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
