<template>
  <component :is="invoiceData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات الإشتراك
      </h4>
      <div class="alert-body">
        لم يتم العثور على الإشتراك مع معرف الإشتراك هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'invoices-list'}"
        >
          قائمة الإشتراك
        </b-link>
        الإشتراك الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="invoiceData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('invoice_edit') }}</span>
        </template>
        <invoice-edit-tab-information
          :invoice-data="invoiceData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import invoiceStore from '../invoiceStore';
import InvoiceEditTabInformation from './InvoiceEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    InvoiceEditTabInformation,
  },
  setup() {
    const invoiceData = ref(null);

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice';

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: invoice } = response.data;
        invoiceData.value = invoice;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          invoiceData.value = undefined;
        }
      });

    return {
      invoiceData,
    };
  },
};
</script>

<style>

</style>
